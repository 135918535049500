export const state = () => ({
    user: null,
    company: null,
    loading: false,
    vkLoginLink: null,
    isVkLoginLinkLoading: false,
    googleLoginLink: null,
    isGoogleLoginLinkLoading: false,
    previewUserAvatar: null,
    previewCompanyLogo: null,
});

export const getters = {
    isCompany(state) {
        return state?.user.is_company;
    },

    hasVkIdLink(state) {
        return state?.user?.social_network_and_messanger?.vk || false;
    },

    hasGoogleLink(state) {
        return state?.user?.social_network_and_messanger?.google || false;
    },

    getCount(state) {
        const user = state.user;

        if (user?.is_company) {
            return {
                requests_count: user?.company?.requests_count || 0,
                vacancies_count: user?.company?.vacancies_count || 0,
            };
        }
        return {
            requests_count: user?.requests_count || 0,
            resumes_count: user?.resumes_count || 0,
        };
    },
};

export const actions = {
    async login({ commit, dispatch }, data) {
        try {
            await this.$axios.$post(this.$api.auth.login, data);

            await dispatch('fetchUser');
        } catch (error) {
            console.warn('[Vuex] user/login request failed: ', error);
            throw error;
        }
    },

    async logout({ commit }) {
        try {
            await this.$axios.$post(this.$api.auth.logout);

            commit('SET_USER', null);
        } catch (error) {
            console.warn('[Vuex] user/logout request failed: ', error);
            throw error;
        }
    },

    async switch({ state, commit, dispatch }) {
        try {
            let res = null;

            if (state.user?.is_company) {
                res = await this.$axios.$post(this.$api.company.switch);
            } else {
                res = await this.$axios.$post(this.$api.user.switch);
            }

            commit('SET_IS_COMPANY', res.is_company);

            // Получение уведомлений
            dispatch('responses/fetchInit', null, { root: true });
        } catch (error) {
            console.warn('[Vuex] user/switch request failed: ', error);
            throw error;
        }
    },

    async fetchUser({ commit, dispatch }) {
        try {
            const res = await this.$axios.$get(this.$api.user.me);

            commit('SET_USER', res);

            // Получение уведомлений
            dispatch('responses/fetchInit', null, { root: true });
        } catch (error) {
            if (error.response?.status !== 403) {
                console.warn('[Vuex] user/fetchUser request failed: ', error);
                throw error;
            }
        }
    },

    async createUserCompany({ dispatch }, data) {
        try {
            await this.$axios.$post(this.$api.user.create, data);

            dispatch('pushYandexAnalyticsEvent', 'companyreg');

            await dispatch('fetchUser');
        } catch (error) {
            console.warn('[Vuex] user/createUserCompany request failed: ', error);
            throw error;
        }
    },

    async fetchUserCompany({ commit }) {
        try {
            const res = await this.$axios.$get(this.$api.company.about);

            commit('SET_COMPANY', res);
        } catch (error) {
            console.warn('[Vuex] user/fetchUserCompany request failed: ', error);
            throw error;
        }
    },

    async requestOtpToken({ commit }, data) {
        try {
            return await this.$axios.$post(this.$api.auth.otp, data);
        } catch (error) {
            console.warn('[Vuex] user/requestOtpToken request failed: ', error);
            throw error;
        }
    },

    async validateOtpToken({ commit }, data) {
        try {
            return await this.$axios.$post(this.$api.auth.validateOtp, data);
        } catch (error) {
            console.warn('[Vuex] user/validateOtpToken request failed: ', error);
            throw error;
        }
    },

    async register({ commit, dispatch }, data) {
        try {
            await this.$axios.$post(this.$api.auth.register, data);

            dispatch('pushYandexAnalyticsEvent', 'regorder');

            await dispatch('fetchUser');
        } catch (error) {
            console.warn('[Vuex] user/register request failed: ', error);
            throw error;
        }
    },

    async recoverPassword({ commit }, data) {
        try {
            await this.$axios.$post(this.$api.auth.recoverPassword, data);
        } catch (error) {
            console.warn('[Vuex] user/recoverPassword request failed: ', error);
            throw error;
        }
    },

    async setContact({ commit }, data) {
        try {
            return await this.$axios.$post(this.$api.user.setContact, data);
        } catch (error) {
            console.warn('[Vuex] user/setContact request failed: ', error);
            throw error;
        }
    },

    async changeContact({ commit }, data) {
        try {
            return await this.$axios.$post(this.$api.user.changeContact, data);
        } catch (error) {
            console.warn('[Vuex] user/changeContact request failed: ', error);
            throw error;
        }
    },

    async resetPassword({ commit }, data) {
        try {
            await this.$axios.$post(this.$api.user.resetPassword, data);
        } catch (error) {
            console.warn('[Vuex] user/resetPassword request failed: ', error);
            throw error;
        }
    },

    setUser({ commit }, data) {
        commit('SET_USER', data);
    },

    async changeSearchStatus({ commit }, search_status) {
        try {
            const res = await this.$axios.$post(this.$api.user.changeSearchStatus, {
                search_status,
            });
            commit('SET_SEARCH_STATUS', res.search_status);
        } catch (e) {
            console.warn('[user/changeSearchStatus] failed with ', e);
        }
    },

    setCompanyVerified({ commit }, status) {
        commit('SET_COMPANY_VERIFIED', status);
    },

    // Получить ссылку для авторизации/создания аакаунта через VK ID
    async fetchVkIdLink({ commit }) {
        try {
            commit('SET_IS_VK_LOGIN_LINK_LOADING', true);
            const res = await this.$axios.$get(this.$api.auth.addVkLogin);

            commit('SET_VK_LOGIN_LINK', res.url);
        } catch (error) {
            console.warn('[Vuex] user/fetchVkIdLink request failed: ', error);
            throw error;
        }
    },

    // Получить ссылку для привязки аккаунта к VK ID
    async fetchConnectVkIdLink({ commit }) {
        try {
            commit('SET_IS_VK_LOGIN_LINK_LOADING', true);
            const res = await this.$axios.$get(this.$api.auth.addVkLogin, {
                params: {
                    linked: true,

                },
            });
            commit('SET_VK_LOGIN_LINK', res.url);
        } catch (error) {
            console.warn('[Vuex] user/fetchConnectVkIdLink request failed: ', error);
            throw error;
        }
    },


    async removeVkId({ commit, dispatch }) {
        try {
            commit('SET_IS_VK_LOGIN_LINK_LOADING', true);
            await this.$axios.$post(this.$api.auth.removeVkLogin);

            await Promise.all([
                dispatch('fetchUser'),
                dispatch('fetchConnectVkIdLink'),
            ]);
        } catch (error) {
            console.warn('[Vuex] user/removeVkId request failed: ', error);
            throw error;
        }
    },

    // Получить ссылку для авторизации/создания аакаунта через Google
    async fetchGoogleLink({ commit }) {
        try {
            commit('SET_IS_GOOGLE_LOGIN_LINK_LOADING', true);
            const res = await this.$axios.$get(this.$api.auth.addGoogleLogin);

            commit('SET_GOOGLE_LOGIN_LINK', res.url);
        } catch (error) {
            console.warn('[Vuex] user/fetchGoogleLink request failed: ', error);
            throw error;
        }
    },

    // Получить ссылку для привязки аккаунта к Google
    async fetchConnectGoogleLink({ commit }) {
        try {
            commit('SET_IS_GOOGLE_LOGIN_LINK_LOADING', true);
            const res = await this.$axios.$get(this.$api.auth.addGoogleLogin, {
                params: {
                    linked: true,

                },
            });
            commit('SET_GOOGLE_LOGIN_LINK', res.url);
        } catch (error) {
            console.warn('[Vuex] user/fetchConnectGoogleLink request failed: ', error);
            throw error;
        }
    },

    async removeGoogle({ commit, dispatch }) {
        try {
            commit('SET_IS_GOOGLE_LOGIN_LINK_LOADING', true);
            await this.$axios.$post(this.$api.auth.removeGoogleLogin);

            await Promise.all([
                dispatch('fetchUser'),
                dispatch('fetchConnectGoogleLink'),
            ]);
        } catch (error) {
            console.warn('[Vuex] user/removeGoogle request failed: ', error);
            throw error;
        }
    },

    pushYandexAnalyticsEvent(_, key) {
        if (process.client) {
            if (window.ym) {
                const METHOD = 'reachGoal';
                const YANDEX_METRIC_ID = 96624921;

                window.ym(YANDEX_METRIC_ID, METHOD, key);
            } else {
                const msg = JSON.stringify('[user/pushYandexAnalyticsEvent] failed: window.ym is not found.');
                console.warn(msg);
                this.app.$sentry.captureException(msg);
            }
        }
    },

    async validateMagicLinkToken({ state, commit }, token) {
        try {
            return await this.$axios.$post(this.$api.auth.validateMagicLink, {
                token,
            });
        } catch (error) {
            console.warn('[user/validateMagicLinkToken] failed with ', error);
            throw error;
        }
    },
};

export const mutations = {
    SET_USER(state, payload) {
        state.user = payload;
    },

    SET_COMPANY(state, payload) {
        state.company = payload;
    },

    SET_CONTACT_PROGRESS(state, payload) {
        state.user.company.filling_contact_progress = payload;
    },

    SET_ABOUT_PROGRESS(state, payload) {
        state.user.company.filling_about_progress = payload;
    },

    SET_IS_COMPANY(state, payload) {
        state.user.is_company = payload;
    },

    SET_SEARCH_STATUS(state, status) {
        state.user.search_status = status;
    },

    SET_COMPANY_VERIFIED(state, payload) {
        state.user.company.verified = payload;
    },

    SET_VK_LOGIN_LINK(state, payload) {
        state.vkLoginLink = payload;
        state.isVkLoginLinkLoading = false;
    },

    SET_IS_VK_LOGIN_LINK_LOADING(state, payload) {
        state.isVkLoginLinkLoading = payload;
    },
    SET_GOOGLE_LOGIN_LINK(state, payload) {
        state.googleLoginLink = payload;
        state.isGoogleLoginLinkLoading = false;
    },

    SET_IS_GOOGLE_LOGIN_LINK_LOADING(state, payload) {
        state.isGoogleLoginLinkLoading = payload;
    },

    SET_PREVIEW_AVATAR(state, payload) {
        state.previewUserAvatar = payload;
    },
    SET_PREVIEW_LOGO(state, payload) {
        state.previewCompanyLogo = payload;
    },
};
